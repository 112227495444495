<template>
    <b-modal id="edit-article"  ref="modal" size="md" class="w-100" title="Modifier Article" @shown="bani">

        <form ref="form">
            <div>
                <label >Nom d'Article</label>
                <div class="form-group form-group-label check-groupe">
                    <multiselect v-model="product_selected"
                                 :options="products"
                                 track-by="name"
                                 label="name"
                                 :close-on-select="true"
                                 :multiple="false"
                                 :searchable="true"
                                 :clear-on-select="false"
                                 :show-no-results="false"
                                 :show-labels="false"
                                 noResult='Not Found'
                                 tag-placeholder="Modifier"
                                 placeholder="Nom Article"
                    >
                    </multiselect>
                </div>

            </div>
            <div v-if="!is_manual">

           <b-form-group v-for="(value, name,index) in products_attribute" :label="name" v-bind:key="(value,name)">
                <b-form-select  :options="value"  v-model="selectedItem.facture_detail_attributes.ids_attribute[index]"  value-field="id_attribute"
                        text-field="name"></b-form-select>
            </b-form-group>
            </div>

            <b-form-group label="Entrez des Designations" v-if="is_manual">
                <b-form-textarea  v-model="manual_designations"></b-form-textarea>
            </b-form-group>


               <b-form-group label="Quantite" >
                <b-form-input type="number" v-model="qte"></b-form-input>
            </b-form-group>

            <b-form-group label="Prix unitaire" >
                 <b-form-input type="number" v-model="unit_price" :disabled="!is_manual"></b-form-input>
            </b-form-group>

            <b-form-group label="Prix totale" >
                <b-form-input type="number" v-model="pt" disabled></b-form-input>
            </b-form-group>





        </form>
        <template #modal-footer>
            <div class="w-100">
                <b-button variant="success" size="lg" class="float-right" @click="editItem">Modifier</b-button>
                <b-button variant="info" size="lg" class="float-left" @click="addManual" v-if="!is_manual">Ajouter Manual</b-button>
                <b-button variant="info" size="lg" class="float-left" @click="cancel" v-if="is_manual">Cancel</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
     import Multiselect from 'vue-multiselect'
    export default {
        name: "EditArticle",
        components: {Multiselect},
        // props:['products','product_selected_p','qte_p','unit_price','designation_selected','id_facture_detail'],
        props:['products','id'],

        data() {
            return {
                // product_selected:this.product_selected_p,
                products_attribute:[],

                types: [
                    { value: 0, text: 'Facture' },
                    { value: 1, text: 'Bon De Commande' },
                ],
                product_selected: {},
                designation_selected:[],
                selectedItem:{
                    facture_detail:{
                        qte:1,
                        unit_price:1,
                    },

                    facture_detail_attributes:{
                        ids_attribute:[],
                    },
                },
                manual_designations:'',
                is_manual:false,
                qte : 1,
                unit_price:1,
                price:'',
                changed:false,




            }
        },
        computed: {


                pt: function () {
                if(this.qte <=0) return  0
                return  parseInt(this.qte) * parseFloat(this.unit_price)
                }
  },
          watch:{
        'product_selected.id_product': function(){
            if(this.product_selected.id_product && !this.is_manual && this.changed)   this.getAttributes()
         },
        'selectedItem.facture_detail_attributes.ids_attribute': function(){
            let is_complete = true;
            this.selectedItem.facture_detail_attributes.ids_attribute.forEach(item => {if(typeof (item) == "undefined") is_complete = false })
            if (is_complete && this.selectedItem.facture_detail_attributes.ids_attribute.length == Object.keys(this.products_attribute).length && this.selectedItem.facture_detail_attributes.ids_attribute.length !=0) {
                this.getPrice();
                }
            else {console.log("mzl")}
            }

     },


        methods: {
            addManual(){
                this.is_manual = true
                this.unit_price = 0
                this.id_product_attribute = 0

            },
            cancel(){
                this.is_manual = false
                this.unit_price = this.price
                this.manual_designations = ""
                this.getAttributes()

            },

            bani(){
                this.is_manual = false
                this.designation_selected = this.designation_selected.splice(0,0);
                this.manual_designations = ''

                this.$http
                    .get('on_update_facture_detail/' + this.id)
                    .then(response => {

                        this.selectedItem = response.data
                        if(response.data.facture_detail.attribut_particular){
                            this.is_manual = true
                            this.manual_designations = response.data.facture_detail.attribut_particular
                        }
                        this.qte = this.selectedItem.facture_detail.qte
                        this.unit_price = this.selectedItem.facture_detail.unit_price
                        this.price = this.selectedItem.facture_detail.unit_price
                        this.product_selected   = {
                            "name":this.selectedItem.facture_detail.article.name,
                            "id_product":this.selectedItem.facture_detail.id_product
                        }
                        this.changed = true
                        let a= JSON.parse(JSON.stringify(this.selectedItem.facture_detail_attributes.ids_attribute))
                        for(let i=0;i<a.length;i++) {
                            this.designation_selected[i] = a[i]
                        }


                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            getPrice(){
                     this.$http
                    .post('getPrices', {"product_id":this.product_selected.id_product,"ids_attribute":this.selectedItem.facture_detail_attributes.ids_attribute.join()})
                    .then(response => {

                        this.unit_price = response.data.price;
                        this.id_product_attribute = response.data.id_product_attribute;
                        console.log(response)

                    })
                    .catch(error => {
                        console.log(error)
                        this.$alertify.error("Cette Désignation n'est pas disponible :(");
                    })
            },

                getAttributes(){

                 this.$http
                    .get('products/'+this.product_selected.id_product+'/product_attribute')
                    .then(response => {
                        let products_attribute = response.data.attributes.reduce((r, a) => {
                        r[a.attribute_name] = [...r[a.attribute_name] || [], a];
                        return r;
                        }, {});

                        this.products_attribute = products_attribute;
                        this.designation_selected = this.designation_selected.splice(0,0);


                        if(response.data.is_designations == 0){
                            this.unit_price = response.data.unit_price[0]
                        }

                    })
                    .catch(error => {
                        console.log(error)
                        this.$alertify.error("Il y a une erreur, réessayez !");
                    })

            },

            editItem() {

                    let fact_detail = {
                        "id_product":this.product_selected.id_product,
                        "qte":this.qte,
                        "id_attribute":this.id_product_attribute,
                        "attribut_particular":this.manual_designations,
                        "unit_price":this.unit_price
                };


                this.$http
                    .post('factures_detail/' + this.id, fact_detail)
                    .then(response => {
                        this.$emit('refresh')
                        this.$refs['modal'].hide()
                        this.$alertify.success("L'opération a réussi :)")
                        console.log(response)
                        this.products_attribute = {}
                    })
                    .catch(error => {
                        console.log(error)
                        if(error.response.data.message == "duplicate")this.$alertify.error("Element existant !")
                        else this.$alertify.error("Il y a une erreur, réessayez !");
                    })
            },

        },

    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
