<template>
    <main>
        <div class="row  m-0">
            <div class="col-xl-4 mt-4">
                <h1>Factures</h1>
            </div>
        </div>
        <div class="row  ">
            <div class="col-xl-12  mr-0">
                <div class="card ">
                    <div class="card-body">
                        <form>
                            <div class="row mb-4">

                                <div class="col-xl-2 col-sm-3">
                                    <div class="form-group mt-3 mb-0">

                                        <b-form-input type="search" v-model="filter_all"
                                                      placeholder="Recherche"></b-form-input>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-sm-3">
                                    <div class="form-group mt-3 mb-0 ">
                                <download-excel
                                        class   = "btn btn-secondary"
                                        name    = "factures.xls"
                                        header = "Factures"
                                        :fields = "json_fields"
                                        :data   = "factures_table">
                                    Telecharger
                                </download-excel>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-sm-3">
                                    <div class="form-group mt-3 mb-0 ">
                                        <b-button class="w-100" @click="$bvModal.show('add')" variant="info" size="sm">
                                            Nouvelle Facture
                                        </b-button>
                                    </div>
                                </div>


                            </div>
                        </form>

                        <!-- Table -->
                        <table class="text-center">
                            <thead>
                            <tr>
                                <th scope="col">Num Facture</th>
                                <th scope="col">Nom du Client</th>
                                <th scope="col">Details</th>
                                <th scope="col">Date</th>
                                <th scope="col">Type</th>
                                <th scope="col">Prix</th>
                                <th scope="col">Cree par</th>
                                <th scope="col">Actions</th>


                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in factures_table" :key='item.id'>
                                <td data-label="Num Facture"> {{item.num_facture}}</td>
                                <td data-label="Nom du Client"> {{item.client_name}}</td>
                                <td data-label="Details">
                                    <p class="margin-no ">

                                        <span class="label label-black statut"> reduction : <span>{{item.reduction}}%</span></span><br>
                                        <span class="label label-green statut"> avance :<span>{{item.avance}} DH</span></span><br>
                                        <span class="label label-red statut"> reglé :
                                            <span v-if="item.avance == item.total_price">Oui</span>
                                             <span v-else>Non</span>
                                        </span><br>
                                    </p>
                                </td>
                                <td data-label="date"> {{item.date_transaction}}</td>

                                <td data-label="Type"> {{types[item.type]}}</td>
                                <td data-label="Prix"> {{item.total_price}}</td>
                                <td data-label="Cree par"> {{item.user.name}}</td>
                                <td data-label="Actions">
                                    <ul class="list-inline  mb-0 ml-1">
                                        <li class="list-inline-item m-0 "  @click="$router.push({name:'EditItem',params:{id:item.id}})">
                                            <i class="fas fa-edit"></i></li>
                                        <li class="list-inline-item m-0 " @click="deleteItem(item.id)">
                                            <i class="fas fa-trash-alt" v-if="$is_admin"></i></li>
                                        <li class="list-inline-item m-0 " @click='imprimer(item.id)'>
                                            <i class="fas fa-print"></i></li>
                                    </ul>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="row mt-2" v-if="factures_table.length">
                            <div class="col">
                                <div class="text-center">
                                    <Pagination :current_page=current_page :last_page_url=last_page_url
                                                v-on:change-page="changePage"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <add-item   v-on:refresh="refresh_data"/>

        <delete-confirmation :id="selectedId" type="factures/" v-on:refresh="refresh_data"/>
    </main>
</template>

<script>
    import Pagination from "@/components/pagination";

    import DeleteConfirmation from "@/components/deleteConfirmation";
    import AddItem from "@/components/AddItem";

    export default {
        name: "Factures",
        components: { AddItem, DeleteConfirmation, Pagination},
        data() {
            return {
                current_page: 1,
                types: [ 'Facture','Bon De Commande'],
                json_fields: {
                    'Numero Facture': 'num_facture',
                    'Nom de Client': 'client_name',
                    'Date De Transaction': 'date_transaction', // nested attribute supported
                    'reduction': 'reduction',
                    'avance': 'avance',
                    'Type': {
                        field: 'type',
                        callback: (value) => {
                            return this.types[value]
                        },
                    },
                    'Prix': 'total_price',
                },
                factures: [],
                filter_all: '',
                show_item: 5,
                selectedId: '',
                selectedItem: {}
            }
        },
        computed: {
            factures_table: function () {
                var factures_filtred = this.search();
                return [].concat(factures_filtred.slice((this.current_page - 1) * this.show_item, this.current_page * this.show_item));

            },
            last_page_url : function () {
                let factures_filtred = this.search();
               return  Math.ceil(factures_filtred.length / this.show_item);
            }
        },
        watch: {
            filter_all: function () {
                this.refresh_data()
                this.current_page = 1;
            },
        },
        created() {
            this.refresh_data()

        },
        methods: {

            deleteItem(id) {
                this.selectedId = id
                this.$root.$emit('bv::show::modal', 'delete')

            },
            imprimer(id) {
                window.open("/facture/" + id, "", "width=800, height=600");

            },
            getAll() {
                this.$http
                    .get('factures')
                    .then(response => {
                        this.factures = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            changePage(page) {
                this.current_page = page
            },
            refresh_data() {
                this.getAll()
                // this.factures_table = this.search();
                // // this.factures_table = [].concat(this.factures_filtred.slice(0,  this.show_item));
                // this.last_page_url = Math.ceil(this.factures_table.length / this.show_item);
            },
            search() {

                return this.factures.filter(item => {
                    let num_facture = item.num_facture.toString().toLowerCase();
                    let client_name = item.client_name.toString().toLowerCase();
                    let reduction = item.reduction.toString().toLowerCase();
                    let avance = item.avance.toString();
                    let regle = item.regle.toString().toLowerCase();
                    let date = item.date_transaction.toString().toLowerCase();
                    let type = item.type.toString().toLowerCase();
                    let price = item.total_price.toString();
                    let searchTerm = this.filter_all.toLowerCase();

                    return   num_facture.includes(searchTerm)
                        || client_name.includes(searchTerm)
                        || reduction.includes(searchTerm)
                        || avance.includes(searchTerm)
                        || regle.includes(searchTerm)
                        || date.includes(searchTerm)
                        || type.includes(searchTerm)
                        || price.includes(searchTerm)


                });
            },
        }
    }

</script>

<style scoped src="@/assets/table.css">

</style>