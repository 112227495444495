<template>
    <main>
        <div class="row mt-5">
            <div class="col-xl-4 mb-2">
                <div class="card paiement bg-white border">
                    <div class="card-main">
                        <div class="card-header bg-transparent">
                            <div class="card-inner">
                                <i class="fas fa-info-circle fa-2x"></i>
                                <b class="card-heading" style="font-size: 26px">
                                    Informations
                                </b>
                                <i class="fas fa-print fa-2x  ml-4 float-right" style="cursor: pointer" @click="imprimer"></i>
                                <i class="fas fa-pencil-alt fa-2x float-right" style="cursor: pointer" @click="showEditInfo"></i>

                            </div>
                        </div>
                        <div class="card-inner p-2">
                            <div class="row">
                                <div class="col-xl-4">Num Facture:</div>
                                <div class="col-xl-8">{{item.num_facture}}</div>
                            </div>

                            <div class="row" v-if="item.mode">
                                <div class="col-xl-4">ICE:</div>
                                <div class="col-xl-8">{{item.ice}}</div>
                            </div>
                            <div class="row">
                                <div class="col-xl-4">Client:</div>
                                <div class="col-xl-8">{{item.client_name}}</div>
                            </div>
                            <div class="row">
                                <div class="col-xl-4">Type:</div>
                                <div class="col-xl-8">{{types[item.type]}}</div>
                            </div>
                            <div class="row">
                                <div class="col-xl-4">Date:</div>
                                <div class="col-xl-8">{{item.date_transaction}}</div>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="card paiement bg-white mt-4 mb-2 border">
                    <div class="card-main">
                        <div class="card-header bg-transparent">
                            <div class="card-inner">
                                <i class="fas fa-dollar-sign fa-2x"></i>
                                <b class="card-heading" style="font-size: 26px">

                                    Paiement
                                </b>
                                <i class="fas fa-print fa-2x  ml-4 float-right" style="cursor: pointer" @click="imprimer_recu"></i>
                                <i class="fas fa-save fa-2x float-right" style="cursor: pointer" @click="editItem"></i>

                                <!--                               <button @click="imprimer('pay')" class="btn btn-black pull-left" style="border-radius: 8px"><i-->
                                <!--                                       class="icon icon-lg" style="margin-left: 4px">save</i>وصل الدفع-->
                                <!--                               </button>-->
                            </div>
                        </div>
                        <div class="card-inner p-2">

                            <div class="row">
                                <div class="col-xl-6">
                                    <b-form-group label="Reduction">
                                        <b-form-select v-model="item.reduction" :options="reductions"></b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-xl-6">
                                    <b-form-group label="Total HT">
                                        <b-form-input type="number" v-model="total_ht" disabled></b-form-input>
                                    </b-form-group>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-xl-6">
                                    <b-form-group label="TVA">
                                        <b-form-input type="number" v-model="tva" disabled></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-xl-6">
                                    <b-form-group label="Total TTC">
                                        <b-form-input type="number" v-model="total_ttc" disabled></b-form-input>
                                    </b-form-group>
                                </div>


                            </div>
                            <div class="row">
                                <div  :class=" {
                            'col-xl-6': `${val}` == 'true',
                            'col-xl-3': `${val}` == 'false',

                             }">
                                    <b-form-group label="Complet">
                                <b-form-checkbox size="lg"  switch v-model="ok"></b-form-checkbox>
                                    </b-form-group>
                                </div>
                                <div class="col-xl-3" v-if="!val">
                                    <b-form-group label="Avance">
                                        <b-form-input type="text" v-model="item.avance"></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-xl-6">
                                    <b-form-group label="Reste">
                                        <b-form-input type="number" v-model="reste" disabled></b-form-input>
                                    </b-form-group>
                                </div>


                            </div>
                            <div class="row ">
                                <div class="col-xl-6">
                                    <b-form-group label="Mode de Reglement">
                                        <b-form-select type="text" v-model="item.mode_reglement" :options="modes"></b-form-select>
                                    </b-form-group>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div class="col-xl-8">
                <table class="text-center">
                    <thead>
                    <tr>
                        <th scope="col">Article</th>
                        <th scope="col">Attributs</th>
                        <th scope="col">Quantite</th>
                        <th scope="col">Prix Unitaire</th>
                        <th scope="col">Prix Totale</th>
                        <th scope="col">Actions</th>


                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in facture_details" :key='item.id'>
                        <td data-label="Article"> {{item.article.name}}</td>
                        <td data-label="Attributs" style="min-width: 300px !important;text-align: left">
                            <p class="margin-no " v-for="att in item.attributes" :key='att.id_attribute' >
                               <b v-if="!item.attribut_particular">
                                <b>- {{att.attribute_name}} :</b> {{att.name}}<br>
                               </b>

                            </p>
                            <p class="margin-no " v-if="item.attribut_particular">- {{item.attribut_particular}}</p>
                        </td>
                        <td data-label="Quantite"> {{item.qte}}</td>
                        <td data-label="Prix Unitaire"> {{item.unit_price}}</td>
                        <td data-label="Prix Totale"> {{item.pt}}</td>
                        <td data-label="Actions">
                            <ul class="list-inline  mb-0 ml-1">
                                <li class="list-inline-item m-0 " @click="showEdit(item.id)"><i class="fas fa-edit"></i>
                                </li>
                                <li class="list-inline-item m-0 " @click="deleteItem(item.id)"><i
                                        class="fas fa-trash-alt" role="button"></i></li>
                                <!--                               <li class="list-inline-item m-0 " @click='imprimer(item.id)'>-->
                                <!--                                   <i class="fas fa-print"></i></li>-->
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td data-label="Article"></td>
                        <td data-label="Attributs"></td>
                        <td data-label="Quantite"></td>
                        <td data-label="Prix Unitaire"></td>
                        <td data-label="Prix Totale"></td>
                        <td data-label="Actions">
                            <ul class="list-inline  mb-0 ml-1">
                                <li class="list-inline-item m-0 ">
                                    <i class="fas fa-plus-circle" @click="$bvModal.show('add-article')"
                                       role="button"></i></li>
                                <add-article :products="products" v-on:refresh="refresh_data"/>

                            </ul>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
<!--        <div class=" row mt-5">-->


<!--            <div class="col-xl-6">-->
<!--                <button type="submit" class="btn btn-danger " style="width: 150px"><i class="fas fa-check mr-2"></i>done-->
<!--                </button>-->
<!--            </div>-->
<!--            <div class="col-xl-6">-->
<!--                <button type="submit" class="btn btn-success  float-right" style="width: 150px"><i-->
<!--                        class="fas fa-save mr-2"></i> save-->
<!--                </button>-->
<!--            </div>-->


<!--        </div>-->


        <edit-info :item_info="item" v-on:refresh="refresh_data"/>
        <edit-article
                :products="products"
                :id="selectedId"
                v-on:refresh="refresh_data"
        />
        <!--        :product_selected_p="product_selected"-->
        <!--        :qte_p ="selectedItem.facture_detail.qte"-->
        <!--        :unit_price="selectedItem.facture_detail.unit_price"-->
        <!--        :designation_selected="selectedItem.facture_detail_attributes.ids_attribute"-->
        <!--        :id_facture_detail ="selectedItem.facture_detail.id_facture"-->
        <delete-confirmation :id="selectedId" type="factures_detail/" v-on:refresh="refresh_data"/>

    </main>
</template>

<script>

    import DeleteConfirmation from "@/components/deleteConfirmation";
    import AddArticle from "@/components/AddArticle";
    import EditArticle from "@/components/EditArticle";
    import EditInfo from "@/components/EditInfo";

    export default {
        name: "EditItem",
        components: {EditInfo, EditArticle, AddArticle, DeleteConfirmation},
        data() {
            return {
                facture_details: [],
                types:['Facture','Bon De Commande'],
                reductions: [
                    {value: 0, text: '0 %'},
                    {value: 5, text: '5 %'},
                    {value: 10, text: '10 %'},
                    {value: 15, text: '15 %'},
                    {value: 20, text: '20 %'},
                ],
                modes:[
                    {value: 0, text: 'Carte Bancaire'},
                    {value: 1, text: 'Virement'},
                    {value: 2, text: 'Espece'},
                    {value: 3, text: 'Cheque'},
                ],
                selectedId: '',
                val:false,
                item: { },
                selectedInfo: {},
                products: [],


            }
        },
        created() {
            this.refresh_data();
            this.getProducts();

        },
        computed: {

            total_ttc: function () {
                let sum_op = (max, cur) => max + cur;
                let sum = this.facture_details.map(el => parseFloat(el.pt)).reduce(sum_op, 0);
                let ttc = sum * (1 - (this.item.reduction) / 100);
                if (Number.isInteger(ttc)) return ttc
                else return ttc.toFixed(2)
            },
            tva: function () {
                return (parseFloat(this.total_ttc) / 6).toFixed(2)
            },
            total_ht: function () {
                return (parseFloat(this.total_ttc) - parseFloat(this.tva)).toFixed(2)
            },

            reste: function () {
                return (parseFloat(this.total_ttc) - parseFloat(this.item.avance)).toFixed(2)
            },
            ok:{
                get: function() {
                    return (parseFloat(this.item.avance) == parseFloat(this.total_ttc)) ? true : false
                },
                set: function(newValue){
                    this.val= newValue;
                    return newValue;
                }

            }
        },
        methods: {


            getProducts() {
                this.$http
                    .get('products')
                    .then(response => {
                        this.products = response.data;
                        console.log(response)

                    })
                    .catch(error => {
                        console.log(error)
                        this.$alertify.error("Il y a une erreur, réessayez !");
                    })

            },
            imprimer() {
                window.open("/facture/" + this.$route.params.id, "", "width=800, height=600");

            },
            imprimer_recu(){
                window.open("/recu/" + this.$route.params.id, "", "width=800, height=600");
            },

            showEdit(id) {

                this.selectedId = id;
                this.$root.$emit('bv::show::modal', 'edit-article')

            },
            showEditInfo() {
                this.$root.$emit('bv::show::modal', 'edit-info')
            },
            deleteItem(id) {
                this.selectedId = id
                this.$root.$emit('bv::show::modal', 'delete')

            },
            getFacture() {
                this.$http
                    .get('factures/' + this.$route.params.id)
                    .then(response => {
                        this.item = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            getFactureDetails() {
                this.$http
                    .get('factures_detail/' + this.$route.params.id)
                    .then(response => {
                        this.facture_details = response.data
                        this.facture_details.forEach(function (item) {
                            if(response.data.attribut_particular)item.attribut_particular = response.data.attribut_particular
                            item.pt = parseFloat(item.qte) * parseFloat(item.unit_price)
                        });
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            refresh_data() {
                this.getFacture();
                this.getFactureDetails();
            },
            editItem() {
                if(this.val) this.item.avance = this.total_ttc
                this.$http
                    .post('factures/' + this.$route.params.id, this.item)
                    .then(response => {
                        console.log(response)
                        this.refresh_data();
                        this.$alertify.success("L'opération a réussi :)")


                    })
                    .catch(error => {
                        console.log(error)
                        this.$alertify.error("Il y a une erreur, réessayez !");
                    })
            },

        },

    }
</script>
<style scoped src="@/assets/table.css">

</style>
