<template>
    <b-modal id="edit-info"  ref="modal" size="lg" class="w-100" title="Modifier" @shown="bani">

        <form ref="form">
            <div class="row">

                <div class="col-xl-4 m-auto">
            <b-form-group >
                <b-form-radio-group
                        id="radio-group-1"
                        v-model="mode"
                        :options="options"
                        name="radio-options"
                ></b-form-radio-group>
            </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4" v-if="!$is_admin">
                    <b-form-group label="Date de Transaction" label-for="date-input">
                        <b-form-input type="datetime-local" id="date-input" v-model="date_transaction"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-xl-4" v-if="$is_admin">
                        <b-form-group label="Numero de Facture" label-for="num-input">
                        <b-form-input id="num-input" v-model="num_facture"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-xl-4">
                    <b-form-group label="Nom De Client" label-for="name-input">
                        <b-form-input id="name-input" v-model="client_name"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-xl-4">
                    <b-form-group label="Type" >
                        <b-form-select v-model="type" :options="types"></b-form-select>
                    </b-form-group>
                </div>
            </div>
            <div class="row" >

                <div class="col-xl-4" v-if="$is_admin">
                    <b-form-group label="Date de Transaction" label-for="date-input">
                        <b-form-input type="datetime-local" id="date-input" v-model="date_transaction"></b-form-input>
                    </b-form-group>
                </div>
                <div  class="col-xl-4" v-if="$is_admin">
                    <b-form-group label="CAISSE_N">
                        <b-form-checkbox size="lg"   v-model="ok"></b-form-checkbox>
                    </b-form-group>
                </div>
                <div class="col-xl-4" v-if="mode == 1">
                    <b-form-group label="ICE" label-for="ice-input">
                        <b-form-input id="ice-input" v-model="ice"></b-form-input>
                    </b-form-group>
                </div>
<!--                <div class="col-xl-6">-->
<!--                    <b-form-group label="Type" label-for="type-input">-->
<!--                        <b-form-select id="type-input" :options="types"  v-model="item_info.type"></b-form-select>-->
<!--                    </b-form-group>-->
<!--                </div>-->
            </div>


        </form>
        <template #modal-footer>
            <div class="w-100">
                <b-button variant="success" size="lg" class="float-right" @click="editItem">Modifier</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>

    export default {
        name: "EditInfo",
        props:['item_info'],

        data() {
            return {

                options: [
                    { text: 'Particulier', value: 0 },
                    { text: 'Professionel', value: 1 },
                ],
                date_transaction:'',
                mode:0,
                num_facture:'',
                client_name:'',
                type:0,
                ice:'',
                ok:false,

                types: [
                    { value: 0, text: 'Facture' },
                    { value: 1, text: 'Bon De Commande' },
                    // { value: 'd', text: 'This one is disabled', disabled: true }
                ]
            }
        },
        methods: {
            bani(){
                this.date_transaction = this.parseTime(this.item_info.date_transaction)
                this.mode = this.item_info.mode
                this.num_facture = this.item_info.num_facture
                this.client_name = this.item_info.client_name
                this.type = this.item_info.type
                this.ice = this.item_info.ice

            },

            parseTime(a){
                return a.split(' ')[0]+'T'+a.split(' ')[1];
            },
            editItem() {

                let item={
                    "date_transaction":this.date_transaction,
                    "mode":this.mode,
                    "num_facture":this.num_facture,
                    "client_name":this.client_name,
                    "type":this.type,
                    "ice":this.ice,

                }
                    if(this.ok) item.num_facture = "CAISSE_N"
                    this.$http
                    .post('factures/' + this.item_info.id, item)
                    .then(response => {
                        this.$emit('refresh')
                        this.$refs['modal'].hide()
                        this.$alertify.success("L'opération a réussi :)")
                        console.log(response)
                    })
                    .catch(error => {
                        console.log(error)
                        this.$alertify.error("Il y a une erreur, réessayez !");
                    })
            },
        },

    }
</script>
