<template>
    <b-modal id="add"  ref="modal" size="lg" class="w-100" title="Ajouter Nouveau">

        <form ref="form">
            <div class="row">

                <div class="col-xl-4 m-auto">
                    <b-form-group >
                        <b-form-radio-group
                                id="radio-group-1"
                                v-model="item.mode"
                                :options="options"
                                name="radio-options"
                        ></b-form-radio-group>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6">
                    <b-form-group label="Nom De Client" label-for="name-input">
                        <b-form-input id="name-input" v-model="item.client_name"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-xl-6">
                    <b-form-group label="Type" label-for="type-input">
                        <b-form-select id="type-input" :options="types"  v-model="item.type"></b-form-select>
                    </b-form-group>
                </div>

            </div>
            <div class="row">

                <div class="col-xl-6">
                    <b-form-group label="Date de Transaction" label-for="date-input">

                        <b-form-input type="datetime-local" id="date-input" v-model="item.date_transaction"></b-form-input></b-form-group>
                </div>
                <div class="col-xl-6" v-if="item.mode == 1">
                    <b-form-group label="ICE" label-for="ice-input">
                        <b-form-input id="ice-input" v-model="item.ice"></b-form-input>
                    </b-form-group>
                </div>

            </div>


        </form>
        <template #modal-footer>
            <div class="w-100">
                <b-button variant="success" size="lg" class="float-right" @click="addItem">Ajouter</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>

    export default {
        name: "AddItem",

        data() {
            return {

                options: [
                    { text: 'Particulier', value: 0 },
                    { text: 'Professionel', value: 1 },
                ],
               item:{
                   date_transaction:this.parseTime(new Date()),
                   mode:0
               },
                types: [
                    { value: 0, text: 'Facture' },
                    { value: 1, text: 'Bon De Commande' },
                ]
            }
        },
        methods: {
            parseTime(a){
                return a.getFullYear()+"-"+("0" + (a.getMonth() + 1)).slice(-2)+'-'+("0" + (a.getDate())).slice(-2)+'T'+(a.getHours()<10?'0':'') + a.getHours()+':'+(a.getMinutes()<10?'0':'') + a.getMinutes()
            },
            addItem() {
                this.$http
                    .post('factures', this.item)
                    .then(response => {
                        this.$emit('refresh')
                        this.$refs['modal'].hide()

                        this.item = {
                            'date_transaction':new Date()
                        };
                        this.$router.push({name:'EditItem',params:{id:response.data.id}})
                        this.$alertify.success("L'opération a réussi :)")
                        console.log(response)

                    })
                    .catch(error => {
                        console.log(error)
                        this.$alertify.error("Il y a une erreur, réessayez !");
                    })
            },
        },

    }
</script>
